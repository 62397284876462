import classes from "./accordion.module.css";
import { Container, Button, Row, Col } from 'reactstrap';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import SlideToggle from "react-slide-toggle";
import BezierEasing from "bezier-easing";

export default function Accordion(props) {
    const { label, icon, children } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [toggleEvent, setToggleEvent] = useState(0);

    const toggle = (e) => {
        setIsOpen(!isOpen);
        setToggleEvent(Date.now());
    }


    const accordionClass = classes.AccordionWrapper + (isOpen ? ` ${classes.IsOpen}` : '');
    const accordionEndIconClass = classes.AccordionEndIcon + (isOpen ? ` ${classes.IsOpen}` : '');

    const easeInOutQuad = BezierEasing(0.46, 0.03, 0.52, 0.96);

    return (
        <div>
            <div className={accordionClass} onClick={toggle}>
                <div className={classes.AccordionHeader}>
                    <i className={`fa ${icon}`} aria-hidden="true"></i>
                    <h6 className={classes.AccordionTitle}>{label}</h6>
                    <div className={accordionEndIconClass}>
                        <i className="fa fa-times" aria-hidden="true"></i>
                    </div>
                </div>
            </div>

            <SlideToggle
                toggleEvent={toggleEvent}
                collapsed={true}
                duration={400}
                easeExpand={easeInOutQuad}
                easeCollapse={easeInOutQuad}>
                {({ setCollapsibleElement }) => (
                    <div ref={setCollapsibleElement} className={classes.AccordionContent}>
                        {children}
                    </div>
                )}
            </SlideToggle>
        </div>
    );
}
