const codeSnippet = 
`// Get the path of the entity template asset, in this case stored as "player class" in the level settings.
const C_AssetReference& playerTemplateRef = settings.GetPlayerTemplateAssetRef();

// Load the asset, with 'loadParams' containing options for things like caching behavior
LVL_TemplateAsset* playerTemplateAsset = C_AssetManager::Load<LVL_TemplateAsset>(playerTemplateRef, loadParams);

// Spawn an instance into the world
mPlayerAgent = playerTemplateAsset->GetTemplate()->CreateInstance(mWorld);

// Grab the entity's root component as a PlayerAgentComponent
GCL_PlayerAgentComponent* playerComp = mPlayerAgent->GetRootComponent()->As<GCL_PlayerAgentComponent>();

// Teleport it somewhere
playerComp->Teleport(playerSpawnTransform);`;

export default codeSnippet;