import React, { useState, useEffect, useRef } from "react";
import cls from "./index.module.css";

export default function Image(props) {
    const [imageUrl, setImageUrl] = useState(null);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [height, setHeight] = useState(0);


    const newRef = useRef(null);

    const imgRef = props.parentRef === undefined ? newRef : props.parentRef;

    useEffect(() => {

        const observer = new IntersectionObserver((entries) => {
            const [entry] = entries;
            if (entry.isIntersecting) {
                //console.log(`image ${props.imageName} elem height: ${imgRef.current.height}`);
                const url = `/api/images/${props.imageName}?width=${imgRef.current.width}&height=${imgRef.current.height}`;
                setImageUrl(url);
            }
        });

        if (imgRef.current) {
            observer.observe(imgRef.current);
        }

        return () => {
            if (imgRef.current) {
                observer.unobserve(imgRef.current);
            }
        };



    }, [imgRef]);

    const calculateHeight = () => {
        const aspectRatio = props.imageWidth / props.imageHeight;
        const actualWidth = imgRef.current.clientWidth;
        const height = actualWidth / aspectRatio;
        setHeight(height);
    };

    useEffect(() => {
        calculateHeight();
        window.addEventListener('resize', calculateHeight);

        return () => window.removeEventListener('resize', calculateHeight);
    });


    function handleImageLoad(event) {
        setImageLoaded(true);
    }

    const style = {
        width: `100%`,
        maxWidth: `${props.imageWidth}px`,
        height: `${height}px`,
        backgroundSize: "cover"
    };

    if (props.accuratePlaceholder) {
        return (
            <div style={style}>
                <img
                    className={`${props.className} ${cls.imgFadeOpacity} ${imageLoaded ? (cls.fadeIn) : ""} `}
                    src={imageUrl}
                    alt={props.alt}
                    ref={imgRef}
                    onLoad={handleImageLoad}
                    onClick={props.onClick}
                />
            </div>
        );
    }
    else {
        return (
            <img
                className={`${props.className} ${cls.imgFadeOpacity} ${imageLoaded ? (cls.fadeIn) : ""} `}
                src={imageUrl}
                alt={props.alt}
                ref={imgRef}
                onLoad={handleImageLoad}
                onClick={props.onClick}
            />
        );
    }

}

