import React, { Component } from 'react';
import { Gallery, Item as GalleryItem } from 'react-photoswipe-gallery'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import ReactMarkdown from 'react-markdown'
import { Container, Button, Row, Col } from 'reactstrap';
import cls from "./index.module.css";


export default function FeatureHighlight({content, img, isText}) {

    if (isText) {
        return (
            <div className={`${cls.featureBoxWrapper} ${cls.featureBoxTextOnly}`}>
                <Row>
                    <Col xs={12} md={5} >
                        <div className={`d-flex justify-content-center align-items-center ${cls.featureOutputCol}`}>
                            <img className={cls.featureOutputImage} src={img} />
                        </div>
                    </Col>
                    <Col xs={12} md={7} className={cls.featureTextCol}>
                        <div className={`d-flex justify-content-left align-items-center ${cls.featureOutputCol}`}>
                            <ReactMarkdown children={content} />
                        </div>
                    </Col>
                </Row>
            </div>
        );
    } else {
        return (
            <div className={cls.featureBoxWrapper}>
                <Row style={{ width: 'inherit' }}>
                    <Col md={12} lg={5} className={`${cls.featureColHeader}`}>
                        <div className={cls.featureColTitle}>OUTPUT</div>
                    </Col>
                    <Col md={12} lg={7} className={`${cls.featureColHeader} d-lg-block d-none`}>
                        <div className={cls.featureColTitle}>CODE</div>
                    </Col>
                    <Col md={12} lg={5} >
                        <div className={`d-flex justify-content-center align-items-center ${cls.featureOutputCol}`}>
                            <img className={cls.featureOutputImage} src={img} />
                        </div>
                    </Col>
                    <Col md={12} lg={7} className={`${cls.featureColHeader} d-lg-none d-block`}>
                        <div className={cls.featureColTitle}>CODE</div>
                    </Col>
                    <Col md={12} lg={7} className={cls.featureCodeCol}>
                        <SyntaxHighlighter
                            className=""
                            language="cpp"
                            style={nord}
                            showLineNumbers
                            wrapLines
                            customStyle={{
                                fontSize: "13px",
                                background: "transparent",
                                margin: 0
                            }}>
                            {content}
                        </SyntaxHighlighter>
                    </Col>
                </Row>
            </div>
        );
    }
}

