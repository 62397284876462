const codeSnippetAnimSysVarSetter = 
`void SetWalkSpeedVariable(AN_AnimController* aCtrl, float aValue)
{
    AN_VariableId varId = aCtrl->GetVariableId<float>("WalkSpeed");

    if (varId != AN_INVALID_VARIABLE)
        aCtrl->SetVariableValue(varId, aValue);
}`;

const codeSnippetTriggerState =
`if (aParams.mMoveVelocity.length() <= thresholdForIdle && !mMovement.mIsAccelerating)
{
    GetAnimController()->SetStateMachineState("Locomotion", "EndWalk_RightFoot");
    mMovement.mMoveVelocity = Vector3::zero();
}`

export { codeSnippetAnimSysVarSetter, codeSnippetTriggerState };