import codeSnippet from "./codeSnippets/spawnEntityTemplate";
import { codeSnippet as codeSnippetPropertySystem, codeSnippetPropertyList } from "./codeSnippets/propertySystem";
import { codeSnippetBasicView } from "./codeSnippets/viewWidget";
import { codeSnippetAnimSysVarSetter, codeSnippetTriggerState } from "./codeSnippets/animSys";
import { codeSnippetEntityComponentSelector} from "./codeSnippets/entities";

const data = [
    { "type": "title", "label": "Level Editor", },
    { "type": "text", "content": 
        "The Level Editor is where all game logic comes together! Levels can be tested immediately by using play-in-viewport."
    },
    { "type": "video", "url": "./vid/leveleditor_1.mp4"},
    { "type": "text", "content": [
        "Entities can be composed of different components. Entities can be defined directly in the level editor, but they can also be instanced by using \"template entities\". This is similar to \"prefabs\" in Unity, or \"blueprints\" in Unreal Engine."
    ]},

    { "type": "container", "label": "Entity Templates", "icon": "fa-money", "content": [
        { "type": "text", "content":
            "Much like \"prefabs\" in Unity or \"Blueprints\" in Unreal Engine, Warlock Engine supports predefining an entity so that it is easily reusable. The entity is saved as an asset, and can be instanced multiple times from either code or the level editor."
        },
        { "type": "image", "url": "co_template_editor.png", "width": 1600, "height": 900 },
        { "type": "text", "content": 
            "Once an Entity Template has been saved as an asset, it can be loaded and spawned like this:"
        },
        { "type": "accordion", "label": "Code Snippet: Spawning an Entity Template from code", "icon": "fa-code", "content": [
            { "type": "code", "content": codeSnippet}
        ]}
        
    ]},
    { "type": "container", "label": "Scene Hierarchy", "icon": "fa-money", "content": [
        { "type": "text", "content":
            `The Level Editor features a "virtual" scene hierarchy that is separate from the scene's node hierarchy. This way, level editor "nodes" can also act as folders. It still keeps the scene's physical hierarchy in mind, so entities can be parented to other entities from within the level editor's scene hierarchy.`
        },
        { "type": "image", "url": "co_scene.png", "width": 1600, "height": 900 }
    ]},
    { "type": "container", "label": "Referencing Entity Components", "icon": "fa-money", "content": [
        { "type": "text", "content":
            `Every Entity and Entity Component can be referenced via a Unique ID, even Entity Templates. Therefore, binding to different components within the same entity, to other entities or to components within instanced templates works seamlessly.`
        },
        { "type": "text", "content":
            `Entity Components can store a handle to another component, which can be passed into a UI component for selecting a component. The dependency management, UID generation for templates and resolving of the actual component all happens behind the scenes.`
        },
        { "type": "featureHighlight", "code": codeSnippetEntityComponentSelector, "img": "./img/co_entity_component_selector.png" }
    ]},
    { "type": "container", "label": "Multiple Viewports", "icon": "fa-money", "content": [
        { "type": "text", "content":
            `One viewport is never enough! A common limitation in game engines is that you can only have one viewport of the game while playing. This is very annoying as a developer, hence I made it a priority to properly support multiple viewports. You can have as many viewports as you'd like, and debug drawing is localized to each viewport. You can even set a different camera (or freecam) for each viewport.`
        },
        { "type": "video", "url": "./vid/multiple_viewports.mp4"}
    ]},

    { "type": "separator" },
    { "type": "title", "label": "Animation System" },

    { "type": "text", "content": 
        "The Animation System uses a Node Graph to composite a pose using State Machines and procedural transformations. The editor shows which part of the graph is currently being evaluated by highlighting those nodes. In this example I show how modifying animation variables affect the output."
    },

    { "type": "video", "url": "./vid/animsys_1.mp4"},

    { "type": "container", "label": "State Machines", "icon": "fa-money", "content": [
        { "type": "text", "content": 
            "Animation State Machines are a flexible way of defining animation logic from within the animation graph. State Machines are independent from the rest of the graph, thus they always evaluate to a pose that can be further altered. State Machines can also be placed within state nodes."
        },
        { "type": "image", "url": "co_animFSM.png", "width": 1398, "height": 434 },
        { "type": "text", "content": 
            "The flow of which states can be entered from another is defined by connecting the states together. Unfortunately it becomes a bit messy, it would be optimal if state nodes were also to accept connections vertically."
        },
        { "type": "text", "content": 
            `### State Nodes 
State nodes are essentially group nodes with an output pose. They define the state machine's animation output while the state is active.`
        },

        { "type": "accordion", "label": "Node Graph Snippet: Example of an Animation State", "icon": "fa-code", "content": [
            { "type": "image", "url": "co_animState.png", "width": 1171, "height": 628 },
        ]},

        { "type": "text", "content": 
            `### Conditions 
States can be entered/exited in a few different ways:
##### 1. Through code
A particular state local to a state machine can be triggered from code:`},

        { "type": "code", "content": codeSnippetTriggerState },
        { "type": "text", "content":
`##### 2. Automatically for "one shot" animation states
Animations that are defined as "one shot" (i.e. non-looping) can automatically trigger a state to exit when they reach (or are close to) the end of the animation.` },

        { "type": "featureHighlightText", "img": "./img/co_animStateExitCondition.png", "content": 
`The Animation State Node's properties contains a list of each of the "transitions" from that state to others. Here we can define the type of transition flow to use, for example based on the state "ending".` },

        { "type": "text", "content":
`##### 3. Using condition variables
Each state node has a "Can Enter" pin on the result node. This pin is evaluated, and when it evaluates to 'true', that state is entered. `
        },

        { "type": "featureHighlightText", "img": "./img/co_animStateConditionNodes.png", "content": 
`Condition variables can use any kind of logic normal animation variables can. This allows for complex logic within animation states.` },
        
    ]},
    { "type": "container", "label": "Expression/Curve Nodes", "icon": "fa-money", "content": [
        { "type": "text", "content": 
            "Warlock Engine's animation system features a 'curve' and 'expression' node. The curve node has a built-in curve editor to define a curve. The expression node uses 'exprtk' to allow for scripting within the animation graph. Using special syntax, input pins are dynamically created based on the expression, and can be hooked up to other variables or even curves to sample from!"
        },
        { "type": "image", "url": "co_anim_curve_expr.png", "width": 1375, "height": 546 },
    ]},
    { "type": "container", "label": "Code Driven Variables", "icon": "fa-money", "content": [
        { "type": "featureHighlightText", "img": "./img/content/animvariables.png", "content": `
Animation Variables are used to communicate state from the game to the animation system.  
They can be used to control animation behavior as well as to create conditional logic within the graph to drive state machines.` },
        { "type": "text", "content": 
            "Animation Variables can be queried and controlled from code. A simple example of setting a variable:"
        },
        { "type": "code", "content": codeSnippetAnimSysVarSetter},
        { "type": "text", "content": 
            "This is just an example, it's a good idea to cache the variable ID as it only needs to be queried once."
        },
    ]},
    { "type": "container", "label": "Animation Events", "icon": "fa-money", "content": [
        { "type": "text", "content": 
            `Animation Nodes can emit any number of "Animation Events" that can then, depending on their context and point of execution, be deferred into other systems in the engine. Thanks to the flexibility of Warlock Engine's Editor UI, implementing a timeline with such behavior is easy. An example of an implementation of this can be seen here:`
        },

        { "type": "image", "url": "co_animEventsTimeline.png", "width": 525, "height": 276 },
    ]},

    { "type": "separator" },
    { "type": "title", "label": "Sequences" },

    { "type": "image", "url": "slide_seq.png", "width": 1600, "height": 900 },

    { "type": "text", "content":
        "Sequences are a flexible way to animate objects within Warlock Engine. They are essentially just a hierarchy of items that can hold data, where each item can have a set of child items. Any item in this hierarchy can also be instanciated, where the execution logic is unique to a \"sequence player\", but still contains shared data."
    },

    { "type": "text", "content":
        "Sequences in Warlock Engine provide a number of options to represent data on a timeline. This includes a basic ```SequenceItem``` that exists for the entire duration of the sequence, a ```SequenceTrack```, which contain ```SequenceClips``` with a begin and end time, as well as ```SequenceChannels```, which contain keyframes."
    },

    { "type": "container", "label": "Resource Bindings", "icon": "fa-money", "content": [
        { "type": "text", "content":
            "Sequence Items can expose any number of resources. Sequence Resources are bits of data that can be filled in by other systems in the engine. For example, a binding of a specific entity. Let's say we have a scene with a cube, and an entity with a sequence component:"
        },

        { "type": "accordion", "label": "Image: Example scene with a cube", "icon": "fa-image", "content": [
            { "type": "image", "url": "co_seq_scene.png", "width": 1600, "height": 900 },
        ]},
        
        { "type": "text", "content":
            "We can now animate this specific cube by opening the sequence from within the Level Editor. We can now add the cube straight from the Sequence Editor:"
        },
        { "type": "featureHighlightText", "img": "./img/co_seq_pick_entity.png", "content": 
`In this case the Sequence Editor can directly access entities from the scene, and bind a resource to them directly.` },

        { "type": "video", "url": "./vid/seq_animate_cube.mp4"},

        { "type": "text", "content":
            "Now, let's say we want to add a few more cubes, and play the same sequence on each one, from their respective location."
        },

        { "type": "featureHighlightText", "img": "./img/co_seq_res_override.png", "content": 
`We can achieve this by overriding the sequence's existing entity resource, and picking another cube entity from our level. This means that our sequence remains the same, but it will simply use another resource we have defined externally instead of the cube we had previously added to the sequence.` },

        { "type": "text", "content":
            "Now, if we play the level, we can see more cubes are animating using the exact same sequence!"
        },

        { "type": "accordion", "label": "Video: Animating multiple entities with a single sequence", "icon": "fa-play", "content": [
            { "type": "video", "url": "./vid/seq_animate_more_cubes.mp4"},

            { "type": "text", "content":
                "Resource bindings are essentially independent from the sequence. This means that they have their own \"resolve\" step, and can be provided to the sequence either from the editor or from code. The Sequence Resource instance defines its own UI and properties and can contain any kind of logic necessary to resolve the resource instance."
            },

            { "type": "text", "content":
                "In the case of entity resources, we can also bind to \"self\" - meaning the entity that started the sequence. This allows us to use the sequence in an Entity Template, which can then be instanciated a number of times, with the sequence binding the entity to its template instance:"
            },

            { "type": "video", "url": "./vid/seq_animate_even_more_cubes.mp4"},
        ]}
    ]},

    { "type": "separator" },
    { "type": "title", "label": "Node Graphs" },

    { "type": "text", "content":
        "The Node Graph system in Warlock Engine is perfect for compositing any kind of logic and data and has some nice features, such as reroute nodes, group nodes and comment nodes. The following is an example implementation in a finance application, completely unrelated from Warlock's main use as a \"game engine\"."
    },

    { "type": "image", "url": "co_node_graph.png", "width": 1423, "height": 784 },

    { "type": "text", "content":
        "Node Graphs can be \"compiled\" down to an efficient execution graph. This process creates \"instances\" of nodes and also inlines reroute and group nodes. After this, a node graph can be executed with different contexts, allowing for re-use of the same compiled graph."
    },

    { "type": "text", "content":
        "Node Graphs are used within a variety of systems. Currently this includes the animation system and audio cues:"
    },

    { "type": "image", "url": "co_audio_graph.png", "width": 1349, "height": 586 },

    { "type": "separator" },
    { "type": "title", "label": "Editor UI" },

    { "type": "text", "content":
        "As some might know, I absolutely adore ImGUI."
    },
    { "type": "text", "content":
        "Warlock Engine makes full use of it, and takes advantage of some of the more experimental features such as 'docking' and 'viewports'. I've already shown some custom UI for node graphs, scene hierarchy and timelines, but here are a few more examples of custom UI developed using ImGUI for Warlock Engine:"
    },

    { "type": "container", "label": "The 'View' Widget", "icon": "fa-money", "content": [
        { "type": "text", "content":
            `One of the main design goals behind Warlock Engine is the ability to quickly spawn a viewport and draw some stuff in it. Warlock's View Widget comes in 2 flavors:
- **Tool_ViewWidget**: This is a basic view widget which can take a number of render callbacks to perform drawing. It can be used for either 2D or 3D drawing.
- **Tool_WorldViewWidget**: This view widget renders a "world", which can either be passed to it or created automatically. A world object can contain entities, physics, subsystems and more.

The following example shows how to in-line create a new 3D view widget and render it. Views and rendering is handled on a higher level, but the widget takes care of registering a world automatically if none was passed to the widget.`
        },

        { "type": "featureHighlight", "code": codeSnippetBasicView, "img": "./img/content/3dview_basic.png" }
    ]},
    
    { "type": "container", "label": "Property Grid", "icon": "fa-money", "content": [
        { "type": "text", "content":
            "Even though ImGUI already allows for rapid development of editor UIs, I wanted to have a more classic property grid for larger lists of settings. Complex property grids exist, even for ImGUI, but I wanted to keep the simplicity of ImGUI and have as little UI state as possible."
        }, 
        { "type": "text", "content":
            "The use of ImGUI's tables API makes for a neat overview of key/value-style properties. Through templating it becomes effortless to make complex property grids. For example, the following code produces a small property grid:"
        },
        { "type": "featureHighlight", "code": codeSnippetPropertySystem, "img": "./img/content/property_simple.png" },

        { "type": "text", "content":
            "Lists are also supported. The entry type is automatically derived and passed into a callback, which makes for a pretty neat API:"
        },

        { "type": "featureHighlight", "code": codeSnippetPropertyList, "img": "./img/content/property_list.png" },
        { "type": "text", "content":
            "List properties also have automatic UI for adding and removing entries. The behavior of this can be altered through the API."
        }, 
    ]},
    { "type": "container", "label": "Canvas Widget", "icon": "fa-money", "content": [
        { "type": "text", "content":
            `The Canvas Widget is an integral part of many editors in Warlock Engine. It's used in the sequence editor, the curve editor and even the node graph editor. The canvas has many features:
- Multiple axes
- Independent axis zoom
- Zoom-to-cursor
- Integrated timeline (horizontal and vertical axis)
- Grid/subdivision-grid
- Snapping
- Fit-to-view/content
- Multi-canvas with synchronized axes
- Drag & Drop support
- Mouse/pointer capture `},

        { "type": "video", "url": "./vid/canvas_example.mp4"},

        { "type": "text", "content":
    `This is an example of (arguably a bit too strong) snapping behavior of the canvas, as seen in the Sequence Editor:`
        },

        { "type": "video", "url": "./vid/canvas_snapping.mp4"},
    ]},
   
    { "type": "container", "label": "Profiler", "icon": "fa-cogs", "content": [
        { "type": "text", "content":
        `It's a basic profiler, it measures time and stuff. What more can I say.`
        },
        { "type": "image", "url": "co_profiler.png", "width": 766, "height": 426 },
    ]},

    { "type": "container", "label": "Hex Editor", "icon": "fa-cogs", "content": [
        { "type": "text", "content":
        `I've also implemented a Hex Editor, which is very useful for all sorts of data analysis purposes! It lets you define highlight regions, virtual/physical addresses, and can have a flexible number of columns/display styles.`
        },
        { "type": "image", "url": "co_hex_editor.png" },
    ]},

    { "type": "separator" },
    { "type": "title", "label": "Applications" },

    { "type": "text", "content":
        "I've used Warlock Engine for a variety of projects at this point. I've used it to make games, data analysis tools, modding tools, and also as a generic utilities library. Here are a few examples:"
    },

    { "type": "container", "label": "Character Controller demo", "icon": "fa-money", "content": [
        { "type": "text", "content":
            "This was the first and major \"use case\" to build Warlock Engine. I wanted to take remnants of an old and cancelled game and try to salvage them into a working prototype. There were some animations, some models, some textures, but it required a lot of manual stitching together and reverse engineering to figure out how the data worked. The game that this was about is Dinosaur Planet, developed by Rare in the late 90s for the Nintendo 64. The game was ultimately cancelled, but bits of the game got out through a demo of its successor, Star Fox Adventures. The fragments you've seen on this page are from this project."
        },
        { "type": "text", "content":
            "It's important to realize that I've used as many \"native\" assets from Dinosaur Planet as possible. For example, the animations used for the character are using original Nintendo 64 animations under the hood, using a re-implementation of their animation system reverse engineered from MIPS assembly."
        },
        { "type": "text", "content":
            "The sequences/cutscenes are also based on as much original data as possible. I've reverse engineered and reimplemented the sequencing system used in Dinosaur Planet to generate sequences editable by hand in my editor. The same goes for environment models, dialogue, music, etc."
        },
        { "type": "text", "content":
            "(Skip to about 1:10 for \"gameplay\")"
        },
        { "type": "video", "url": "./vid/dptribute_demo.mp4"},

        { "type": "text", "content":
            "The following shows me testing a feature that makes the character bank left/right when running and turning simultaneously:"
        },

        { "type": "video", "url": "./vid/dptribute_banking.mp4"},
    ]},
    { "type": "container", "label": "Dinosaur Planet Mapviewer", "icon": "fa-money", "content": [
        { "type": "image", "url": "co_mapviewer.png" },

        { "type": "text", "content":
            "Dinosaur Planet was a game developed by Rare for the Nintendo 64. The game was ultimately cancelled, but a build was leaked early 2021. I reverse engineered parts of the game's code and wrote a map viewer for it. I perform some heavy optimizations to make it render efficiently in a WebGL environment, but as a result the entire map can render without culling at a stable framerate."
        },
    ]},
    
    { "type": "container", "label": "Mupen64 Emulator Frontend", "icon": "fa-money", "content": [
        { "type": "text", "content":
            "Now that I had a mapviewer for Dinosaur Planet, I thought it would be fun to implement Mupen64, an N64 emulator, into Warlock. It allows widgets within the engine to directly read/write state from/to the emulator, which allows for fun tricks like this:"
        },

        { "type": "video", "url": "./vid/mupen_frontend.mp4"},
    ]},
    { "type": "container", "label": "Detroit: Become Human modding tools", "icon": "fa-money", "content": [
        { "type": "text", "content":
            "I can't stay away from messing with Quantic Dream games :)"
        },

        { "type": "image", "url": "co_detroit_tool.png" },
    ]},

    { "type": "separator" },
    { "type": "title", "label": "FAQ" },

    { "type": "accordion", "label": "Yeah that's cool, but how many lines of code?", "icon": "fa-question", "content": [
        { "type": "text", "content":
`Warlock Engine's framework modules count a total of **1407 files** and **128,351 lines of code** at time of writing.
Note that this ***only*** counts for the core framework of Warlock Engine. None of the libraries, library "support" code, or application code is included.`
        }
    ]},

    { "type": "accordion", "label": "Can I download Warlock Engine anywhere?", "icon": "fa-question", "content": [
        { "type": "text", "content":
            "No. Warlock Engine is currently closed-source. Since it is a project that I made on a (very) limited time budget, Warlock Engine is polished in areas that mattered to me, but not in others. It is not really usable except by me as a sandbox to experiment in."
        }
    ]},
    { "type": "accordion", "label": "Why is it not open source?", "icon": "fa-question", "content": [
        { "type": "text", "content": 
            "When I start a project, I usually think from the beginning if I want to open source it or not. Public code acts as a portfolio piece to me. In the case of Warlock Engine, it was always intended as my own personal \"toolbelt\", and not something I'd want to get public involvement in."
        },
        { "type": "text", "content":
            "Open-sourcing Warlock Engine would mean I'd want to dedicate a lot of time to improving crucial code areas of the engine, offer support to users/contributors and maintain the repository. It also means I require the entirety of the code to be of certain quality, which it currently isn't in all areas."
        },
        { "type": "text", "content":
            "Unfortunately I cannot spend more time on the project, hence it is only being presented here as a portfolio piece. I appreciate the interest though! There have been cases where people wanted to work on a specific project within Warlock Engine and I have allowed people access to the code in those cases."
        }
    ]}

    
]

export default data;
