const codeSnippet = 
`void PortfolioApp::UpdateProperties(Properties* aProps)
{
    if (ImGui::Begin("My Cool Window"))
    {
        Tool_PropertyTable::Begin("coolProps");

        WARProp(aProps->mSomeBoolean);
        WARProp(aProps->mSomeInteger);
        WARProp(aProps->mMyFloat);

        Tool_PropertyTable::End();
    }

    ImGui::End();
}`;

const codeSnippetPropertyList =
`WARPropList(aProps->mEntries, [](ListEntry& entry)
{
    WARProp(entry.mIndex);
    WARProp(entry.mEnable);
    WARProp(entry.mPosition);
});`;

export { codeSnippet, codeSnippetPropertyList };