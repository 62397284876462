const codeSnippetBasicView = 
`void PortfolioApp::UpdateView()
{
    ImGui::SetNextWindowSize(ImVec2(500, 300), ImGuiCond_FirstUseEver);

    if (ImGui::Begin("My Cool 3D View"))
    {
        if (!mViewWidget)
            mViewWidget = new Tool_WorldViewWidget();

        mViewWidget->UpdateWidget();
    }

    ImGui::End();
}`;

export { codeSnippetBasicView };