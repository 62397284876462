import React, { Component, useState, useEffect, useRef } from "react";
import { VelocityComponent } from 'velocity-react';
import "./index.module.css";

export class PortfolioNavbar extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showMenu: false,
		};
	}

	componentDidMount() {
		this.setState({ showMenu: true });
	}

	render() {

		const doMenuItem = (elem) => {

			let targetElem = elem.target;
			while (targetElem && !targetElem.getAttribute('data-id')) {
				targetElem = targetElem.parentElement;
			}

			if (targetElem == null) {
				return;
			}

			const targetId = targetElem.getAttribute('data-id');

			if (targetId == undefined) {
				return;
			}

			/* Go back to the home page */
			window.location.href = "https://hugo.fyi/" + targetId;
		}

		const { showMenu } = this.state;

		return (
			<VelocityComponent animation='transition.slideDownIn' runOnMount duration={500} delay={1000}>
				<div className="menubar-container">
					<div className="menubar-bg"></div>

					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-8 col-sm-4 col-md-3 col-lg-3 col-xl-2" data-id="#" onClick={doMenuItem}>
								<div className="noselect menubar-title">
									<span>Hugo Peters</span>
								</div>
							</div>

							<div className="col-sm-6 col-md-9 col-lg-9 col-xl-10">
								<div className="menubar-items-container">
									<div className="menubar-item" data-id="#about" onClick={doMenuItem}>
										<div className="noselect menubar-item-text">
											<span><i className="fa fa-lightbulb-o" aria-hidden="true"></i>   ABOUT</span>
										</div>
									</div>
									<div className="menubar-item" data-id="#professional-projects" onClick={doMenuItem}>
										<div className="noselect menubar-item-text">
											<span><i className="fa fa-users" aria-hidden="true"></i>   PROFESSIONAL PROJECTS</span>
										</div>
									</div>
									<div className="menubar-item" data-id="#personal-work" onClick={doMenuItem}>
										<div className="noselect menubar-item-text">
											<span><i className="fa fa-heart" aria-hidden="true"></i>   PERSONAL WORK</span>
										</div>
									</div>
									<a href="https://hugo.fyi/HugoPetersCV.pdf" style={{ border: 'none', textDecoration: 'none', color: 'white' }} target="_blank" className="menubar-item">
										<span className="noselect menubar-item-text"><i className="fa fa-file-text" aria-hidden="true"></i>   RESUME</span>
									</a>
									<div className="menubar-item" data-id="#contact" onClick={doMenuItem}>
										<div className="noselect menubar-item-text">
											<span><i className="fa fa-comments" aria-hidden="true"></i>   CONTACT</span>
										</div>
									</div>
								</div>
							</div>

							{/*<div class="col-xs-4 col-sm-2">*/}
							{/*	<div id="menubar-hamburger-icon" class="hamburger hamburger--spin" style="color: white;">*/}
							{/*		<div class="hamburger-box">*/}
							{/*			<div class="hamburger-inner"></div>*/}
							{/*		</div>*/}
							{/*	</div>*/}
							{/*</div>*/}
						</div>
					</div>
				</div>
			</VelocityComponent>
		);
	}
}

export default PortfolioNavbar;
