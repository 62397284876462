import classes from "./index.module.css";
import Image from "../Image"
import { Container, Row, Col } from 'reactstrap';

export default function FeatureCard({ item }) {
    return (
        <div style={{ width: '100%', display: 'contents' }}>
            <div className='d-sm-block d-none'>
                <div className={`${classes.FeatureCardContainer}`}>
                    <Image className={classes.FeatureCardCover} accuratePlaceholder={false} imageName={item.cover} />
                    <div xs="12" sm="6" className={classes.FeatureCardBody}>
                        <h2>{item.title}</h2>
                        {item.highlights.map(item => (
                            <tr className={classes.FeatureBody}>
                                <td style={{ paddingRight: 5 }}>
                                    <i class="smallFeature fa fa-check" aria-hidden="true"></i>
                                </td>
                                <td>
                                    <p style={{ margin: 0 }}>{item}</p>
                                </td>
                            </tr>
                        ))}
                    </div>
                </div>
            </div>

            <div className='d-block d-sm-none' style={{ width: '100%' }}>
                <div className={`${classes.FeatureCardMobileContainer}`}>
                    <div className={classes.FeatureCardMobileCover} >
                        <Image accuratePlaceholder={false} imageName={item.cover} />
                    </div>
                    <div className={`${classes.FeatureCardMobileCover} ${classes.FeatureCardMobileOverlay}`} />
                    <div className={classes.FeatureCardMobileBody}>
                        <h2>{item.title}</h2>
                        {item.highlights.map(item => (
                            <tr className={classes.FeatureBody}>
                                <td style={{ paddingRight: 5 }}>
                                    <i class="smallFeature fa fa-check" aria-hidden="true"></i>
                                </td>
                                <td>
                                    <p style={{ margin: 0 }}>{item}</p>
                                </td>
                            </tr>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
