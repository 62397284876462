import React, { Component } from 'react';
import HeroSlider, { Overlay, Slide, MenuNav } from "hero-slider";
import { Container, Button, Row, Col } from 'reactstrap';
import Wrapper from "./Wrapper";
import WarlockLogo from "./WarlockLogo"
import PortfolioNavbar from "./PortfolioNavbar"
import FeatureCard from "./FeatureCard"
import { VelocityComponent } from 'velocity-react';
import TocNav from "./TocNav"
import AutoPlayVideo from "./AutoPlayVideo"
import Accordion from "./Accordion"
import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item as GalleryItem } from 'react-photoswipe-gallery'
import data from "../content.js"
import SyntaxHighlighter from 'react-syntax-highlighter';
import { nord } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import ReactMarkdown from 'react-markdown'
import FeatureHighlight from "./FeatureHighlight"
import Image from "./Image"
import imageManifest from "../imageManifest.json"
import ReactGA from 'react-ga';


export class Home extends Component {
    static displayName = Home.name;

    constructor() {
        super();
        this.featuresList = [
            {
                title: "Level Editor",
                cover: "ft_leveleditor.png",
                highlights: [
                    "Entity Component System",
                    "Play-in-viewport/simulate",
                    "Multiple level editors simultaneously",
                    "Templated entities/'prefabs'",
                    "Parent to entities/bones",
                    "Multiple viewports w/ debug drawing"
                ]
            },
            {
                title: "Animations",
                cover: "slide_anim.png",
                highlights: [
                    "Nodegraph based",
                    "Code-driven variables",
                    "State Machines",
                    "FBX blendshapes support",
                    "In-line tweakable animation events",
                    "Curve/expression nodes"
                ]
            },
            {
                title: "Sequences",
                cover: "ft_sequences.png",
                highlights: [
                    "Timeline with hierarchy",
                    "Tracks, 'clips', keyframe channels",
                    "Flexible resource binding",
                    "Curve editor",
                    "Data/instance separation",
                    "Open within level editor"
                ]
            },
            {
                title: "Physics",
                cover: "ft_physics.png",
                highlights: [
                    "Powered by Bullet Physics",
                    "Character controller implementation",
                    "Triggers with event system",
                    "Root motion support",
                    "Collision mesh generation",
                    "Cloth mesh support"
                ]
            },
            {
                title: "Audio",
                cover: "ft_audio.png",
                highlights: [
                    "Powered by SoLoud library",
                    "Nodegraph based composition",
                    "MIDI support with FluidSynth",
                    "Effects such as reverb",
                    "Event based audio cues",
                    "Custom MP3 decoder",
                ]
            },
            {
                title: "Editor UI",
                cover: "slide_seq.png",
                highlights: [
                    "Powered by ImGUI",
                    "Advanced custom canvas widget",
                    "Custom timeline/keyframe widgets",
                    "Custom property system",
                    "Custom node graph widgets",
                    "Support for docked layouts"
                ]
            },
            {
                title: "Framework",
                cover: "logo.png",
                highlights: [
                    "Modular and extendible",
                    "Small custom type reflection system",
                    "JSON and binary serialization",
                    "Integrated message bus system",
                    "Asset system w/ platform baking",
                    "Hot-reload using Live++"
                ]
            },
            {
                title: "Build system",
                cover: "ft_buildtool.png",
                highlights: [
                    "Written in C#",
                    "Modules with C#-based build rules",
                    "Separate code repositories",
                    "Visual Studio project generation",
                    "VSCode workspace generation",
                    "Custom incremental compilation"
                ]
            },
            {
                title: "Shaders",
                cover: "ft_shaders.png",
                highlights: [
                    "HLSL, generates other targets",
                    "Preprocessing using MCPP",
                    "Shader variant generation",
                    "Optimization using glsloptimizer",
                    "Shader reflection",
                    "Material Editor"
                ]
            },
            {
                title: "Textures",
                cover: "ft_texture_editor.png",
                highlights: [
                    "Encoding using Basis Universal",
                    "Automatic platform-based transcoding",
                    "Texture streaming",
                    "Texture editor to import cubemaps etc.",
                    "IBL/cubemap probes",
                    "Single encode format for all platforms"
                ]
            },
            {
                title: "Cross-platform",
                cover: "ft_crossplatform.png",
                highlights: [
                    "Windows",
                    "macOS (ARM)",
                    "Emscripten (HTML5/WebAssembly)",
                    "Linux (basic port)",
                    "SDL (basic port)",
                    "Few platform-specific dependencies"
                ]
            },
            {
                title: "Rendering",
                cover: "ft_rendering.png",
                highlights: [
                    "Forward & deferred implementations",
                    "Cascade Shadow Maps (CSM)",
                    "Post-processing graph",
                    "Custom thin renderer abstraction",
                    "FBX model import support",
                    "PBR implementation"
                ]
            }
        ];
    }

    componentDidMount() {
        ReactGA.initialize('UA-89570321-1');
        ReactGA.pageview(window.location.pathname + window.location.search);


        const hash = window.location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const rect = element.getBoundingClientRect();
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                const offset = rect.top + scrollTop;
                window.scrollTo({ top: offset, behavior: 'smooth' });
            }
        }
    }

    render() {

        const makeIdFromTitle = (str) => {
            return str.toLowerCase().replace(/[ \/\\']/g, '-').replace(/\s+/g, '-').replace(/^-+|-+$/g, '');
        };

        const toc = [];
        data.forEach((element) => {
            if (element.type === "title") {
                toc.push({ title: element.label, type: "main", id: makeIdFromTitle(element.label) });
            } else if (element.type === "container") {
                toc.push({ title: element.label, type: "subitem", id: makeIdFromTitle(element.label) });
            }
        });

        function getImageSize(name) {
            const image = imageManifest.find(img => img.name === name);
            return image ? { width: image.width, height: image.height } : null;
        }

        const renderContent = (jsonData) => {
            return jsonData.map((item, index) => {
                switch (item.type) {
                    case "separator":
                        return (
                            <div className="contentSectionSeparator"/>
                        );
                    case "title":
                        return (
                            <div key={index} className="contentSectionHeader" id={makeIdFromTitle(item.label)}>
                                <h1>{item.label}</h1>
                            </div>
                        );
                    case "text":
                        return <ReactMarkdown key={index} children={item.content} />;
                    case "video":
                        return (
                            <div key={index} className="contentBreakWrapper d-flex justify-content-center">
                                <div className="contentBreakItem">
                                    <AutoPlayVideo url={item.url} />
                                </div>
                            </div>
                        );
                    case "image":
                        const fullImageUrl = `img/${item.url}`;
                        const imageSize = getImageSize(item.url);
                        return (
                            <div key={index} className="contentBreakWrapper d-flex justify-content-center">
                                <div className="contentBreakItem">
                                    <Gallery>
                                        <GalleryItem original={fullImageUrl} thumbnail={fullImageUrl} width={`${imageSize.width}`} height={`${imageSize.height}`} >
                                            {({ ref, open }) => (
                                                <Image className="contentImgInline" accuratePlaceholder={true} parentRef={ref} onClick={open} imageName={item.url} imageWidth={imageSize.width} imageHeight={imageSize.height} />
/*                                                <img className="contentImgInline" onClick={open} src={fullImageUrl}/>*/
                                            )}
                                        </GalleryItem>
                                    </Gallery>
                                </div>
                            </div>
                            
                        );
                    case "container":
                        return (
                            <div id={makeIdFromTitle(item.label)}>
                                <h2>{item.label}</h2>
                                {renderContent(item.content)}
                            </div>
                            /*<Accordion key={index} label={item.label} icon={item.icon}>*/
                            //</Accordion>
                        );
                    case "accordion":
                        return (
                            <Accordion key={index} label={item.label} icon={item.icon}>
                                {renderContent(item.content)}
                            </Accordion>
                        );
                    case "code":
                        return (
                            <div key={index} className="contentBreakWrapper d-flex">
                                <SyntaxHighlighter className="contentItemFullWidth"
                                    language="cpp"
                                    style={nord}
                                    customStyle={{
                                        fontSize: "13px",
                                        backgroundColor: "#181818",
                                        margin: 0
                                    }}
                                    showLineNumbers wrapLines>
                                    {item.content}
                                </SyntaxHighlighter>
                            </div>
                        );

                    case "featureHighlight":
                        return (
                            <div key={index} className="contentBreakWrapper d-flex">
                                <FeatureHighlight content={item.code} img={item.img} />
                            </div>
                        );

                    case "featureHighlightText":
                        return (
                            <div key={index} className="contentBreakWrapper d-flex">
                                <FeatureHighlight content={item.content} img={item.img} isText />
                            </div>
                        );
                    default:
                        return null;
                }
            });
        }

    return (
        <div>
            <PortfolioNavbar/>
            <HeroSlider
                height={"800px"}
                accessability={{
                    shouldDisplayButtons: false
                }}
            >
                <Slide
                    background={{
                        backgroundImageSrc: '/img/slide_anim.png',
                        backgroundAnimation: 'zoom'
                    }}
                ></Slide>
                {/*<Slide*/}
                {/*    background={{*/}
                {/*        backgroundImageSrc: '/img/slide_seq.png',*/}
                {/*        backgroundAnimation: 'zoom'*/}
                {/*    }}*/}
                {/*></Slide>*/}
                {/*<Slide*/}
                {/*    background={{*/}
                {/*        backgroundImageSrc: '/img/slide_1.png',*/}
                {/*        backgroundAnimation: 'zoom'*/}
                {/*    }}*/}
                {/*></Slide>*/}
                {/*<Slide*/}
                {/*    background={{*/}
                {/*        backgroundImageSrc: '/img/slide_2.png',*/}
                {/*        backgroundAnimation: 'zoom'*/}
                {/*    }}*/}
                {/*></Slide>*/}
                {/*<Slide*/}
                {/*    background={{*/}
                {/*        backgroundImageSrc: '/img/slide_3.png',*/}
                {/*        backgroundAnimation: 'zoom'*/}
                {/*    }}*/}
                {/*></Slide>*/}
                <Overlay>
                    <Wrapper>
                        <WarlockLogo />
                        <Container fluid="md">
                            <div className="centerText">
                                <h5>Warlock Engine is a game engine/framework that I built from scratch in C++ over the course of multiple years.</h5>
                                <h5>It is a versatile, multi-purpose and extendible framework consisting of a (to me) useful set of features, including:</h5>
                            </div>
                        </Container>
                    </Wrapper>
                </Overlay>
            </HeroSlider>
            <div className="mainContentContainer">
                <Container>
                    <Row xs={1} md={1} lg={1} xl={2} className="gx-3 gy-4">
                        {
                            this.featuresList.map(item => (
                                <Col className="d-flex justify-content-center">
                                    {/*<VelocityComponent animation={{ opacity: 1, marginTop: 0 }} duration={750} easing='easeOutCubic' runOnMount>*/}
                                        <FeatureCard item={item} />
                                    {/*</VelocityComponent>*/}
                                </Col>
                            ))
                        }
                    </Row>

                
                </Container>
                <div className="mainSection">
                    <div className="mainSectionContent">
                        <div className="mainSectionTitle">
                            <h1>Background</h1>
                        </div>
                        <Container className="mainSectionBody">
                            <p>I work on a variety of projects in my spare time. For example, I love making games, reverse engineering existing games, creating tools, even developing custom hardware.</p>
                            <p>Throughout the years I've desired a framework that I could quickly boot up, spawn a viewport in, a scene editor or a sequence editor, dump some data into it and have a way to easily visualize and edit that data. I wanted to have a framework I felt completely comfortable in that has tools that do exactly what I want, without external dependencies that could bring breaking updates for features I don't need.</p>
                            <p>Engines such as Unreal Engine and Unity were an inspiration, however they are big and bulky monsters that take ages to compile, forever to load, are restrictive in licenses and contain a bunch of stuff that I don't need, or that I want slightly different.</p>
                            <p>As such, when I had to deal with a lot of custom, broken data for a project I was working on, I decided to start building a generic framework that could handle such projects. The result is over 5 years of work (on and off) to create essentially a sandbox framework for my projects.</p>
                        </Container>
                    </div>
                </div>

                <Container fluid="md">
                    <Row>
                        <Col xs={12} md={9}>
                            {renderContent(data)}
                        </Col>
                        <Col xs={12} md={3} className="d-md-block d-none">
                            <TocNav items={toc} />
                        </Col>
                    </Row>
                </Container>
            </div>
            
      </div>
    );
  }
}
