import ReactPlayer from 'react-player/lazy'
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';


export default function AutoPlayVideo({ url }) {
    const videoRef = useRef(null);

    const [play, setPlay] = useState(true);

    useEffect(() => {
        let options = {
            rootMargin: "0px",
            threshold: [0.25, 0.75]
        };

        let handlePlay = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setPlay(true);
                } else {
                    setPlay(false);
                }
            });
        };

        let observer = new IntersectionObserver(handlePlay, options);

        observer.observe(videoRef.current);
    });

    return <div ref={videoRef}><ReactPlayer url={url} loop muted controls playsinline playing={play} width="100%" height="100%" /></div>
}
