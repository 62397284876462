import classes from "./index.module.css";
import Lottie from "lottie-react";
import { useRef, useState, useEffect } from 'react';
import warlockLogoAnim from "./warlock_loader.json";

export default function WarlockLogo(props) {
    const lottieRef = useRef();
    const [isPaused, setIsPaused] = useState(true);

    useEffect(() => {
        lottieRef.current.setSpeed(1.8);
        lottieRef.current.playSegments([0, 250], true);
        lottieRef.current.playSegments([250, 380], false);
        setIsPaused(false);
    }, []);

    const lottieDiv = <div><Lottie
        lottieRef={lottieRef}
        animationData={warlockLogoAnim}
        isPaused={isPaused} /></div >;



    return lottieDiv;
}
