import React, { Component, useState, useEffect } from 'react';
import cls from "./index.module.css";

export default function TocNav({ items }) {

    const [activeSection, setActiveSection] = useState('');

    function getActiveSection(toc) {
        for (let i = 0; i < toc.length; i++) {
            const element = document.getElementById(toc[i].id);
            if (element) {
                const rect = element.getBoundingClientRect();
                if (rect.top <= window.innerHeight * 0.1 && rect.bottom >= window.innerHeight * 0.1) {
                    return toc[i].id;
                }
            }
        }
        return null;
    }

    const handleScroll = () => {
        const scrollPosition = window.scrollY;

        const newActiveSection = getActiveSection(items);

        if (newActiveSection == null || document.getElementById(newActiveSection) == null)
            return;

        //console.log(newActiveSection);

        setActiveSection(newActiveSection);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const onClickItem = (e) => {
        const targetElement = e.target.getAttribute('data-id');

        const element = document.querySelector(`#${targetElement}`);
        if (element) {
            const rect = element.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const offset = rect.top + scrollTop - 75;
            window.scrollTo({ top: offset, behavior: 'smooth' });

            // Add an event listener to recalculate the scroll position
            // if the page changes size due to dynamic image loading.
            //const images = element.querySelectorAll('img');
            //images.forEach(img => {
            //    img.addEventListener('load', () => {
            //        const rect = element.getBoundingClientRect();
            //        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            //        const offset = rect.top + scrollTop - 75;
            //        window.scrollTo({
            //            top: offset,
            //            left: 0,
            //            behavior: 'smooth'
            //        });
            //    });
            //});
        }
    }

    return (
        <div className={cls.tocWrapper}>
            <div>
                <h5 className={cls.tocHeader}>CONTENTS</h5>
                <div className={cls.tocListWrapper}>
                    <ol className={cls.tocList}>
                        {items.map((item) => (
                            <li onClick={onClickItem} data-id={item.id}
                                className={`${cls.tocListItem} 
                                            ${item.type === 'subitem' ? cls.tocSubitem : ''}
                                            ${activeSection === item.id ? cls.tocSelected : ''}`}>{item.title}</li>
                        ))}
                    </ol>
                </div>
            </div>
        </div>
    );
}

